import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-router-dom";
import { Button, Segment } from "semantic-ui-react";
import CustomTextArea from "../../app/common/form/CustomTextArea";
import CustomTextInput from "../../app/common/form/CustomTextInput";
import CustomSelectInput from "../../app/common/form/CustomSelectInput";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { Payment, PaymentFormValues } from "../../app/models/payment";
import { useStore } from "../../app/stores/store";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { observer } from "mobx-react-lite";
import CustomDateInput from "../../app/common/form/CustomDateInput";
import { runInAction } from "mobx";
import {
  usePaymentMethodOptions,
  usePaymentTypeOptions,
} from "../../app/common/enums/paymentType";
import { useTranslation } from "react-i18next";

interface Props {
  id?: string;
}

export default observer(function PaymentForm({ id }: Props) {
  const { t } = useTranslation();
  const [payment, setPayment] = useState<PaymentFormValues>(
    new PaymentFormValues()
  );
  const { paymentStore, modalStore, memberStore } = useStore();
  const { createPayment, updatePayment, loading } = paymentStore;
  const commonRequiredMessage = t("paymentForm.requiredMessage");
  const validationSchema = Yup.object({
    paidAmount: Yup.number().required(
      `${t("paymentForm.paidAmountLabel")} ${commonRequiredMessage}`
    ),
    paidAt: Yup.date().required(
      `${t("paymentForm.paymentDateLabel")} ${commonRequiredMessage}`
    ),
  });
  const paymentMethodOptions = usePaymentMethodOptions();
  const paymentTypeOptions = usePaymentTypeOptions();
  const handleFormSubmit = (payment: PaymentFormValues) => {
    if (!payment.id) {
      const newPayment: Payment = {
        ...payment,
        id: uuid(),
        memberId: memberStore.selectedMember?.id!,
      };
      createPayment(newPayment as any).then(modalStore.closeModal);
    } else {
      payment.id = id;
      updatePayment({
        ...payment,
      }).then(() => {
        runInAction(() => {
          paymentStore.tableData.list.set(id!, {
            ...payment,
            paidAt: payment.paidAt,
          });
        });
        modalStore.closeModal();
      });
    }
  };

  useEffect(() => {
    id &&
      paymentStore
        .loadPayment(id)
        .then((payment: any) => setPayment(new PaymentFormValues(payment)));
  }, [id, paymentStore]);

  if (loading) return <LoadingComponent content={t("general.loading")} />;

  return (
    <Segment>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={payment}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <Form
            className="ui form"
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{ paddingBottom: "35px" }}
          >
            <div className="three fields">
              <CustomSelectInput
                label={t("paymentForm.paymentTypeLabel")}
                options={paymentTypeOptions}
                name="paymentType"
                placeholder={t("paymentForm.paymentTypeLabel")}
              />
              <CustomDateInput
                name="paidAt"
                label={t("paymentForm.paymentDateLabel")}
                placeholderText={t("paymentForm.paymentDateLabel")}
                dateFormat="dd.MM.yyyy"
              />
              <CustomTextInput
                type="number"
                name="paidAmount"
                placeholder={t("paymentForm.paidAmountPlaceholder")}
                label={t("paymentForm.paidAmountLabel")}
              />
            </div>

            <div className="three fields">
              <CustomTextInput
                type="text"
                name="bookingText"
                placeholder={t("paymentForm.bookingTextLabel")}
                label={t("paymentForm.bookingTextLabel")}
              />
              <CustomTextInput
                type="text"
                name="reasonForPayment"
                placeholder={t("paymentForm.reasonForPaymentLabel")}
                label={t("paymentForm.reasonForPaymentLabel")}
              />
              <CustomSelectInput
                label={t("paymentForm.paymentMethodLabel")}
                options={paymentMethodOptions}
                name="paymentMethod"
                placeholder={t("paymentForm.paymentMethodLabel")}
              />
            </div>

            {id && !payment.memberId && (
              <div className="three fields">
                <CustomTextInput
                  type="text"
                  name="iban"
                  placeholder={t("paymentForm.ibanLabel")}
                  label={t("paymentForm.ibanLabel")}
                />

                <CustomTextInput
                  type="number"
                  name="memberNumber"
                  placeholder={t("paymentForm.memberNumberLabel")}
                  label={t("paymentForm.memberNumberLabel")}
                />
              </div>
            )}

            <CustomTextArea
              label={t("paymentForm.notesLabel")}
              rows={3}
              name="note"
              placeholder={t("paymentForm.notesLabel")}
            />

            <Button
              disabled={isSubmitting || !dirty || !isValid}
              loading={isSubmitting}
              floated="right"
              positive
              type="submit"
              content={t("general.confirm")}
            />
            <Button
              onClick={() => {
                modalStore.closeModal();
              }}
              floated="right"
              type="button"
              content={t("general.cancel")}
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
});
